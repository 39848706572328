<template>
    <div :class="$style.root">
        <VRow dense>
            <VCol cols="12" md="3">
                <VTextField
                    label="E-mail пользователя"
                    :value="value.email"
                    :disabled="readonly"
                    @input="email => $emit('input', {...value, email })"
                    :rules="emailRules"
                    required
                    outlined
                    dense
                    hideDetails="auto"
                />
            </VCol>
            <VCol cols="12" md="2">
                <VTextField label="Фамилия" :value="value.lastName" :disabled="readonly" @input="lastName => $emit('input', {...value, lastName })" required outlined dense hideDetails="auto" />
            </VCol>
            <VCol cols="12" md="2">
                <VTextField label="Имя" :value="value.name" :disabled="readonly" @input="name => $emit('input', {...value, name })" required outlined dense hideDetails="auto" />
            </VCol>
            <VCol cols="12" md="2">
                <VTextField label="Отчество" :value="value.secondName" :disabled="readonly" @input="secondName => $emit('input', {...value, secondName })" required outlined dense hideDetails="auto" />
            </VCol>
            <VCol cols="12" md="3">
                <PhoneField label="Номер телефона" :value="value.phone" :disabled="readonly" @input="phone => $emit('input', {...value, phone })" required />
            </VCol>
        </VRow>
    </div>
</template>

<script>
import PhoneField from './PhoneField';

export default {
    name: 'Address',
    components: {
        PhoneField,
    },
    props: {
        readonly: {type: Boolean},
        value: {
            type: Object,
            default: () => ({
                email: '',
                name: '',
                lastName: '',
                secondName: '',
                phone: '',
            }),
        },
    },
    data() {
       return {
           emailRules: [
               (v) => !!v || 'E-mail пользователя обязателен к заполнению',
               (v) => /^[A-zА-я0-9_.+-]+@[A-zА-я0-9-]+\.[A-zА-я0-9-.]+$/.test(v) || 'E-mail пользователя должен быть действительным',
           ],
       };
    },
}
</script>

<style module lang="scss">
.root{}
</style>
