<template>
  <div :class="$style.root">
    <VForm v-model="valid" lazy-validation ref="form" @submit.prevent="onSave">
      <VRow>
        <VCol cols="12" md="4">
          <PasswordField label="Текущий пароль" v-model="password" />
        </VCol>
        <VCol v-if="password" cols="12" md="4">
          <PasswordField label="Новый пароль" v-model="newPassword" :rules="rules.password" />
        </VCol>
        <VCol v-if="newPassword && password" cols="12" md="4">
          <PasswordField label="Подтверждение пароля" v-model="newPasswordDuplicate" :rules="[(v) => v === newPassword || 'Пароли не совпадают']" />
        </VCol>
        <VCol v-if="password && newPasswordDuplicate === newPassword" cols="12" class="pt-0">
          <VRow dense align="center">
            <VCol cols="auto">
              <VBtn color="primary" :disabled="newPasswordDuplicate !== newPassword" depressed @click="onSave">Изменить пароль</VBtn>
            </VCol>
            <VCol cols="auto">
              <div class="error--text">Не забудьте сохранить изменения</div>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PasswordField from '@/components/user/Profile/components/PasswordField';

export default {
  name: 'Password',
  components: {
    PasswordField,
  },
  data() {
    return {
      valid: true,
      show: false,
      password: '',
      newPassword: '',
      newPasswordDuplicate: '',
      rules: {
        password: [
          (v) => !!v || 'Обязательно для заполнения',
          (v) => v.length >= 10 || 'Минимум 10 символов',
          (v) => !!v.match(/(?=.*[!@#$%^&*()\-_=|№;:?}{])(?=.*[a-z])(?=.*[A-Z])/g) || 'Пароль должен содержать буквы разных регистров, а также спец. символы',
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      editPassword: 'user/editPassword',
    }),
    onSave: function() {
      if (this.$refs.form.validate()) {
        this.editPassword({
          currentPassword: this.password,
          newPassword: this.newPassword,
          newPasswordDuplicate: this.newPasswordDuplicate,
        }).then(result => {
          if (result) {
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Пароль успешно изменен',
            });
          }
        });
      }
    },
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
